import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [lang, setLang] = useState('en');
  const [rtl, setRtl] = useState(false);

//   useEffect(() => {
//     setTimeout(() => {
//       setLang('ua');
//     }, 1000)
//   });

useEffect(() => {
  const title = 'Travel Insurance Plugin';
  const description = 'Travel Insurance Plugin';
  document.title = title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', description);
}, []);

  return (
    <div className="App">
      {/* <select onChange={(e) => setLang(e.target.value)}>
        <option value="en">en</option>
        <option value="ru">ru</option>
        <option value="ua">ua</option>
        <option value="de">de</option>
        <option value="fr">fr</option>
        <option value="ar">ar</option>
        <option value="zh">zh</option>
        <option value="ja">ja</option>
        <option value="pt">pt</option>
        <option value="it">it</option>
        <option value="hi">hi</option>
        <option value="tr">tr</option>
      </select> */}
      <inrisk-widget
        data-theme='{"main": "#06c", "border": "#E2E2E2"}'
        data-name="onlineContract"
        data-lang={lang}
        data-id="337b8298-96b5-4470-a3f5-dc986163c407"
        data-program="337b8298-96b5-4470-a3f5-dc986163c407"
        data-rtl={rtl}
      ></inrisk-widget>
    </div>
  );
}

export default App;
